import * as moment from 'moment';
import {Moment} from 'moment';
import {StationStateDtoMapper, IStationState, IStationStateDto} from './station-state-dto-mapper';

export class StationStateLogsDtoMapper {

    constructor(private stationStateDtoMapper: StationStateDtoMapper) {
    }

    public mapDto(dto: IStationStateLogsDto): IStationStateLog[] {

        if (!dto || !dto.logs) {
            return [];
        }

        return dto.logs.map((l: any) => ({
            timestamp: moment(l.timestamp),
            stationState: this.stationStateDtoMapper.mapDto(l.stationState)
        }));
    }
}

export interface IStationStateLogsDto {
    logs: Array<{
        timestamp: string;
        stationState: IStationStateDto
    }>;
}

export interface IStationStateLog {
    timestamp: Moment;
    stationState: IStationState;
}
